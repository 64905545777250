import { graphql } from 'gatsby';
import React from 'react';
import { Layout } from '../components/layout/layout';
import { UpdateSection } from '../components/update-section';
import { PageSubHeading } from '../components/layout/page-subheading';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { textOptions } from '../util/rich-text';
import { ParagraphCard } from '../components/cards/paragraph-card';
import { ListCard } from '../components/cards/list-card';
import { PrimaryLink } from '../components/primary-link';

const MosaicImage = ({ src, animateDirection = 'animate-fade-in-down' }) => {
  return (
    <div className="w-44 h-64 rounded-lg overflow-hidden">
      <img
        src={src}
        alt=""
        className={`w-full h-full object-center object-cover ${animateDirection}`}
      />
    </div>
  );
};

const IndexPage = ({ data }) => {
  const homePage = data.allContentfulHome.edges[0].node;
  const execUpates = data.allContentfulExecutiveBlogPost.edges
    .map((node) => node.node)
    .map((node) => ({
      ...node,
      slug: `/executive-committee-updates/${node.slug}`,
    }));

  const { title, lbygDescription, mission, vision, activities } =
    data.contentfulAboutUs;
  const imageSources = homePage.heroImages.map((img) => img.fixed.src);

  return (
    <>
      <Layout title="Home">
        <div className="h-full hero-bg overflow-hidden flex flex-col lg:flex-row lg:items-center lg:justify-between max-w-screen-xl mx-auto">
          {/* Left */}
          <div className="px-4 mt-8 lg:mt-0">
            <div className="lg:max-w-lg text-center md:text-left">
              <h1 className="font-heading font-extrabold tracking-tight text-gray-900 text-6xl">
                {homePage.heroTitle}
              </h1>
              <p className="mt-8 text-xl text-gray-600">
                {homePage.heroSubtitle.heroSubtitle}
              </p>
            </div>
            <div className="mt-5 sm:mt-8 sm:flex justify-center lg:justify-start">
              <PrimaryLink
                text={homePage.ctaLink.linkText}
                link={homePage.ctaLink.linkUrl}
              />
            </div>
          </div>
          {/* Right Desktop */}
          <div className="transform hidden lg:block lg:mt-0">
            <div className="flex items-center space-x-6">
              <div className="flex-shrink-0 grid grid-cols-1 gap-y-6">
                <MosaicImage src={imageSources[0]} />
                <MosaicImage
                  src={imageSources[1]}
                  animateDirection="animate-fade-in-up"
                />
              </div>
              <div className="flex-shrink-0 grid grid-cols-1 gap-y-6">
                <MosaicImage src={imageSources[2]} />
                <MosaicImage
                  src={imageSources[3]}
                  animateDirection="animate-fade-in-up"
                />
                <MosaicImage src={imageSources[4]} />
              </div>
              <div className="flex-shrink-0 grid grid-cols-1 gap-y-6">
                <MosaicImage
                  src={imageSources[5]}
                  animateDirection="animate-fade-in-up"
                />
                <MosaicImage src={imageSources[6]} />
              </div>
            </div>
          </div>
          {/* Right Mobile */}
          <div className="lg:hidden my-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 mx-auto">
            <MosaicImage src={imageSources[0]} />
            <MosaicImage src={imageSources[1]} />
            <MosaicImage src={imageSources[2]} />
            <MosaicImage src={imageSources[3]} />
            <MosaicImage src={imageSources[4]} />
            <MosaicImage src={imageSources[5]} />
          </div>
        </div>
        <div className="my-8">
          <div className="text-center my-8 bg-primary py-6">
            <PageSubHeading
              title="Latest Updates"
              styles={'mt-0 md:text-center'}
            />
          </div>
          <div className="px-4 md:px-0">
            <UpdateSection updates={execUpates} />
          </div>
        </div>
        <div className="text-center my-8">
          <div className="my-8 bg-primary py-6">
            <PageSubHeading title={title} styles={'md:text-center'} />
          </div>
          <div className="px-4 md:px-0">
            <div>
              {lbygDescription && renderRichText(lbygDescription, textOptions)}
            </div>
            <div className="mt-8 lg:mt-24 grid grid-cols-1 gap-y-4 lg:grid-cols-3 lg:gap-x-16">
              <ParagraphCard title="Vision" text={vision.vision} />
              <ParagraphCard title="Mission" text={mission.mission} />
              <ListCard title="Activities" listItems={activities} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allContentfulHome(limit: 1, sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          heroTitle
          heroSubtitle {
            heroSubtitle
          }
          heroImages {
            fixed(width: 600) {
              width
              height
              src
              srcSet
            }
          }
          ctaLink {
            linkText
            linkUrl
          }
        }
      }
    }
    allContentfulExecutiveBlogPost(
      limit: 3
      filter: { slug: { ne: "dummy-content-do-not-delete" } }
      sort: { order: DESC, fields: dateOfPost }
    ) {
      edges {
        node {
          id
          slug
          title
          dateOfPost(formatString: "Do MMMM, YYYY")
          rawDate: dateOfPost
          summary {
            summary
          }
          post {
            raw
          }
          author {
            name
            description {
              description
              childMarkdownRemark {
                html
              }
            }
            displayPicture {
              fixed(width: 400) {
                width
                height
                src
                srcSet
              }
            }
          }
        }
      }
    }
    contentfulAboutUs {
      title
      lbygDescription {
        raw
      }
      activities
      mission {
        mission
      }
      vision {
        vision
      }
      photos {
        fixed(width: 800) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
