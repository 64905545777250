import { Link } from 'gatsby';
import React from 'react';

export const PrimaryLink = ({ text, link }) => (
  <div>
    {link.startsWith('/') ? (
      <Link
        className="w-full text-xl flex items-center justify-center px-8 py-3 sm:p-4 border-2 border-transparent text-base font-semibold rounded-md bg-primary hover:bg-secondary shadow-md"
        aria-label={text}
        to={link}
      >
        {text}
      </Link>
    ) : (
      <a
        className="w-full text-xl flex items-center justify-center px-8 py-3 sm:p-4 border-2 border-transparent text-base font-semibold rounded-md bg-primary hover:bg-secondary shadow-md"
        aria-label={text}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
    )}
  </div>
);
