import React from 'react';

export const PageSubHeading = ({ title, styles }) => {
  return (
    <h2
      className={`text-2xl md:text-3xl text-center md:text-left font-bold text-gray-900 ${styles}`}
    >
      {title}
    </h2>
  );
};
