import React from "react";

export const ParagraphCard = ({ title, text }) => {
  return (
    <div className="flex col-span-1 h-full py-4 px-4 bg-primaryLight rounded-lg shadow-md border-t-8 border-primary">
      <div className="flex flex-1 flex-col text-center">
        <div>
          <h2 className="font-heading text-gray-800 text-2xl md:text-4xl font-bold">
            {title}
          </h2>
        </div>
        <div className="mt-4">
          <p className="text-base text-gray-600 sm:mt-2 text-lg sm:max-w-xl sm:mx-auto lg:mx-0">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};
